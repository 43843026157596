import { createI18n } from "vue-i18n"

const messages = {
  en: {
    moneySymbol: "$",
    hello: "Hello",
    welcomeMessage: "Welcome on your dashboard",
    widget5HeaderMessage: "Upcoming Events",
    widget5HeaderSubtextMessage: "Soon Available",
    widget11HeaderMessage: "Revenue Figure",
    widget11HeaderSubtextMessage: "Soon Available",
    artworks: "Artworks",
    contacts: "Contacts",
    dashboard: "Dashboard",
    addArtwork: "Add an Artwork",
    editArtwork: "Edit an Artwork",
    artworkTitle: "Artwork Title",
    selected: "Selected",
    deleteSelected: "Delete selected",
    limitedEdition: "Limited Edition",
    uniquePiece: "Unique Piece",
    available: "Available",
    notAvailable: "Not Available",
    actions: "Actions",
    see: "See",
    delete: "Delete",
    next: "Next",
    reset: "Reset",
    edit: "Edit",
    description: "Description",
    creationDate: "Creation Date",
    technique: "Technique",
    statusArtwork: "Status",
    serie: "Serie",
    type: "Type",
    public: "Public",
    localization: "Localization",
    dimensions: "Dimensions (in Centimeters)",
    weight: "Weight (in Kilograms)",
    price: "Price",
    signatureDone: "Signed Artwork",
    signaturePlacement: "Signature Placement",
    sureToDelete: "Do you really want to delete this artwork ?",
    irreversibleAction: "This action is permanent.",
    return: "Return",
    chooseType: "Choose a type",
    chooseAvailability: "Choose availability",
    displayPublicCatalog: "Display on the public catalog",
    mediaTitle: "Medias - Artwork photos",
    addMedias: "Add photos",
    saveArtwork: "Save the artwork",
    wait: "Please wait",

    betaUser: "Beta User",
    artist: "Artist",
    profileCompletion: "Profile Completion",
    generalView: "General View",

    myProfile: "My Profile",
    language: "Language",
    signout: "Sign-out",

    detailProfile: "Profile Details",
    editProfile: "Edit Profile",
    publicCatalog: "Public Catalog",
    artistName: "Artist Name",
    completeName: "Full Name",
    email: "Email",
    presentation: "Bio/Presentation",
    website: "Website",
    instagramProfile: "Instagram Profile",
    address: "Address",
    postalCode: "Postal Code",
    city: "City/Locality",
    telephone: "Telephone",
    siretNumber: "SIRET Number (or Company Identifier)",
    affiliationNumber: "Affiliation Number ('Maison des artistes' or 'Agessa')",
    nafCode: "NAF or APE Code",

    connexionInformations: "Connexion Informations - Password",
    deactivateAccount: "Deactivate my Pygma Account",
    deactivateAccountWarning: "You are about to deactivate your account. This action is permanent, and you won't be able to create another Pygma account with the same email in the future.",
    deactivateAccountWarningConfirm: "Confirm the account deletion.",
    accountDeletion: "Account Deletion",
    save: "Save",
    password: "Password",
    passwordChangeText: "The password must have at least 8 caracters, with numbers and symbols.",
    currentPassword: "Current password",
    newPassword: "New password",
    repeatNewPassword: "Confirm the new password",
    update: "Update",
    discard: "Discard",
    resetPasswordButton: "Change Password",

    artworkTableName: "Name",
    artworkTableDate: "Date",
    artworkTableType: "Type",
    artworkTableStatus: "Status",
    artworkTablePrice: "Price",
    artworkTableLocalization: "Localization",
    artworkTableActions: "Actions",

    myContacts: "Contacts",
    addContact: "Add a contact",
    tableContact: "Contact",
    tableContactDate: "Date",
    tableContactActivity: "Activity",
    tableContactTelephone: "Telephone",
    tableContactActions: "Actions",

    editContact: "Edit a contact",
    firstname: "First Name",
    lastname: "Last Name",
    activity: "Activity / Work",
    company: "Company",
    comment: "Comment",
    saveContact: "Save contact",
    
    deleteContactMessage: "Do you really want to delete this contact?",
    deleteContactMessageText: "This action is permanent.",

    craft: "Crafted",
    pages: "Pages",
    profile: "Profile",
    profileOverview: "Overview",
    projects: "Projects",
    campaigns: "Campaigns",
    documents: "Documents",
    connections: "Connections",
    wizards: "Wizards",
    horizontal: "Horizontal",
    vertical: "Vertical",
    account: "Account",
    accountOverview: "Overview",
    settings: "Settings",
    authentication: "Authentication",
    basicFlow: "Basic Flow",
    signIn: "Sign-in",
    signUp: "Sign-up",
    passwordReset: "Password Reset",
    error404: "Error 404",
    error500: "Error 500",
    apps: "Apps",
    chat: "Chat",
    privateChat: "Private Chat",
    groupChat: "Group Chat",
    drawerChat: "Drawer Chat",
    widgets: "Widgets",
    widgetsLists: "Lists",
    widgetsStatistics: "Statistics",
    widgetsCharts: "Charts",
    widgetsMixed: "Mixed",
    widgetsTables: "Tables",
    widgetsFeeds: "Feeds",
    changelog: "Changelog",
    docsAndComponents: "Docs & Components",
    megaMenu: "Mega Menu",
    exampleLink: "Example link",
    modals: "Modals",
    general: "General",
    inviteFriends: "Invite Friends",
    viewUsers: "View Users",
    upgradePlan: "Upgrade Plan",
    shareAndEarn: "Share & Earn",
    forms: "Forms",
    newTarget: "New Target",
    newCard: "New Card",
    newAddress: "New Address",
    createAPIKey: "Create API Key",
    twoFactorAuth: "Two Factor Auth",
    createApp: "Create App",
    createAccount: "Create Account",
    documentation: "Documentation",
    components: "Components",
    resources: "Resources",
    customers: "Customers",
    gettingStarted: "Getting Started",
    customersListing: "Customers Listing",
    customerDetails: "Customers Details",
    calendarApp: "Calendar",
    subscriptions: "Subscriptions",
    getStarted: "Getting Started",
    subscriptionList: "Subscription List",
    addSubscription: "Add Subscription",
    viewSubscription: "View Subscription",
  },
  fr: {
    moneySymbol: "€",
    hello: "Bonjour",
    welcomeMessage: "Bienvenue sur votre tableau de bord",
    widget5HeaderMessage: "Echéances à venir",
    widget5HeaderSubtextMessage: "Bientôt disponible",
    widget11HeaderMessage: "Chiffre d'affaire",
    widget11HeaderSubtextMessage: "Bientôt disponible",
    artworks: "Oeuvres",
    contacts: "Contacts",
    dashboard: "Tableau de bord",
    addArtwork: "Ajouter une œuvre",
    editArtwork: "Modifier une œuvre",
    artworkTitle: "Titre de l'œuvre",
    selected: "Sélectionné",
    deleteSelected: "Supprimer la selection",
    uniquePiece: "Pièce unique",
    limitedEdition: "Edition Limitée",
    available: "Disponible",
    notAvailable: "Non Disponible",
    actions: "Actions",
    see: "Voir",
    delete: "Supprimer",
    next: "Suivant",
    reset: "Reset",
    edit: "Editer",
    craft: "Fabriqué",
    description: "Description",
    creationDate: "Date de création",
    technique: "Technique",
    statusArtwork: "Statut",
    serie: "Série",
    type: "Type",
    public: "Publique",
    localization: "Localisation",
    dimensions: "Dimensions (en Centimètres)",
    weight: "Poids (en Kilogrammes)",
    price: "Prix",
    signatureDone: "Oeuvre Signée",
    signaturePlacement: "Emplacement de la signature",
    sureToDelete: "Voulez-vous vraiment supprimer cette oeuvre ?",
    irreversibleAction: "Cette action est irréversible.",
    return: "Retour",
    chooseType: "Choisir un type",
    chooseAvailability: "Choisir la disponibilité",
    displayPublicCatalog: "Afficher l'oeuvre sur le catalogue public",
    mediaTitle: "Médias - Photos de l'oeuvre",
    addMedias: "Ajouter des visuels",
    saveArtwork: "Enregistrer l'oeuvre",
    wait: "Veuillez Patienter",

    betaUser: "Utilisateur Beta",
    artist: "Artiste",
    profileCompletion: "Complétude du profil",
    generalView: "Vue Générale",

    myProfile: "Mon Profil",
    language: "Langue",
    signout: "Déconnexion",

    detailProfile: "Détails Profil",
    editProfile: "Editer le Profil",
    publicCatalog: "Catalogue Public",
    artistName: "Nom d'artiste",
    completeName: "Nom complet",
    email: "Email",
    presentation: "Bio/Présentation",
    website: "Site Web",
    instagramProfile: "Profil Instagram",
    address: "Adresse",
    postalCode: "Code postal",
    city: "Localité",
    telephone: "Téléphone",
    siretNumber: "Numéro de SIRET",
    affiliationNumber: "Numéro d'affiliation (Maison des artistes ou Agessa)",
    nafCode: "Code NAF ou APE",

    connexionInformations: "Informations de connexion - Mot de passe",
    deactivateAccount: "Désactiver mon compte Pygma",
    deactivateAccountWarning: "Vous allez désactiver votre compte. Cette action est définitive, et vous n'aurez plus la possibilité de créer un autre compte pygma dans le futur.",
    deactivateAccountWarningConfirm: "Confirmer la désactivation de mon compte",
    accountDeletion: "Suppresion de compte",
    save: "Enregistrer",
    password: "Mot de Passe",
    passwordChangeText: "Le mot de passe doit contenir au moins 8 caractères, avec des chiffres et des symboles.",
    currentPassword: "Mot de passe actuel",
    newPassword: "Nouveau mot de passe",
    repeatNewPassword: "Confirmer le nouveau mot de passe",
    update: "Mettre à jour",
    discard: "Annuler",
    resetPasswordButton: "Reset mot de passe",

    artworkTableName: "Titre",
    artworkTableDate: "Date",
    artworkTableType: "Type",
    artworkTableStatus: "Statut",
    artworkTablePrice: "Prix",
    artworkTableLocalization: "Localisation",
    artworkTableActions: "Actions",

    myContacts: "Contacts",
    addContact: "Ajouter un contact",
    tableContact: "Contact",
    tableContactDate: "Date",
    tableContactActivity: "Activité",
    tableContactTelephone: "Téléphone",
    tableContactActions: "Actions",

    editContact: "Modifier un contact",
    firstname: "Prénom",
    lastname: "Nom",
    activity: "Activité / Profession",
    company: "Entreprise",
    comment: "Commentaire",
    saveContact: "Enregistrer le contact",

    deleteContactMessage: "Voulez-vous vraiment supprimer ce contact ?",
    deleteContactMessageText: "Cette action est irréversible.",

    pages: "Pages",
    profile: "Profil",
    profileOverview: "Aperçu",
    projects: "Projets",
    campaigns: "Campagnes",
    documents: "Documents",
    connections: "Connexions",
    wizards: "Sorciers",
    horizontal: "Horizontal",
    vertical: "Verticale",
    account: "Compte",
    accountOverview: "Aperçu",
    settings: "Paramètres",
    authentication: "Authentification",
    basicFlow: "Flux de base",
    signIn: "S'identifier",
    signUp: "Inscrivez-vous",
    passwordReset: "Réinitialisation du mot de passe",
    error404: "Erreur 404",
    error500: "Erreur 500",
    apps: "Applications",
    chat: "Discuter",
    privateChat: "Discussion privée",
    groupChat: "Discussion de groupe",
    drawerChat: "Chat de tiroir",
    widgets: "Widgets",
    widgetsLists: "Listes",
    widgetsStatistics: "Statistiques",
    widgetsCharts: "Graphiques",
    widgetsMixed: "Mixte",
    widgetsTables: "Les tables",
    widgetsFeeds: "Flux",
    changelog: "Journal des modifications",
    docsAndComponents: "Documents & composants",
    megaMenu: "Méga Menu",
    exampleLink: "Exemple de lien",
    modals: "Modals",
    general: "Général",
    inviteFriends: "Inviter Des Amis",
    viewUsers: "Voir Les Utilisateurs",
    upgradePlan: "Plan De Mise À Niveau",
    shareAndEarn: "Partager Et Gagner",
    forms: "Formes",
    newTarget: "Nouvelle Cible",
    newCard: "Nouvelle Carte",
    newAddress: "Nouvelle Adresse",
    createAPIKey: "Créer Une Touche Api",
    twoFactorAuth: "Deux Facteurs D'Authentification",
    createApp: "Créer Une Application",
    createAccount: "Créer Un Compte",
    documentation: "Documentation",
    components: "Composants",
    resources: "Ressources",
    customers: "Les Clients",
    gettingStarted: "Commencer",
    customersListing: "Liste Des Clients",
    customerDetails: "Détails Des Clients",
    calendarApp: "Calendrier",
    subscriptions: "Abonnements",
    getStarted: "Commencer",
    subscriptionList: "Liste D'Abonnement",
    addSubscription: "Ajouter Un Abonnement",
    viewSubscription: "Voir L'Abonnement",
  },
};

const i18n = createI18n({
  legacy: false,
  locale: "en",
  globalInjection: true,
  messages,
});

export default i18n;
