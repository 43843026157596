enum Actions {
  // action types
  ADD_BODY_CLASSNAME = "addBodyClassName",
  REMOVE_BODY_CLASSNAME = "removeBodyClassName",
  ADD_BODY_ATTRIBUTE = "addBodyAttribute",
  REMOVE_BODY_ATTRIBUTE = "removeBodyAttribute",
  ADD_CLASSNAME = "addClassName",
  VERIFY_AUTH = "verifyAuth",
  LOGIN = "login",
  LOGOUT = "logout",
  REGISTER = "register",
  LOAD_PROFILE = "loadProfile",
  UPDATE_USER = "updateUser",
  EDIT_USER_PIC = "updateUserPic",
  FORGOT_PASSWORD = "forgotPassword",
  FORGOT_PASSWORD_CODE = "forgotPasswordCode",
  SET_BREADCRUMB_ACTION = "setBreadcrumbAction",

  ACTIVATE = "activateUser",
  EDIT_USER = "editUser",
  CHANGE_PASSWORD = "changePassword",

  LOAD_ARTWORKS = "loadArtworks",
  LOAD_ARTWORK = "LOAD_ARTWORK",
  CREATE_ARTWORK = "createArtwork",
  EDIT_ARTWORK = "editArtwork",
  DELETE_ARTWORK = "deleteArtwork",

  CREATE_MEDIA = "createMedia",
  DELETE_MEDIA = "deleteMedia",

  LOAD_CONTACTS = "loadContacts",
  LOAD_CONTACT = "loadContact",
  CREATE_CONTACT = "createContact",
  EDIT_CONTACT = "editContact",
  DELETE_CONTACT = "deleteContact",

  LOAD_PUBLIC_USER = "loadPublicUser"
}

enum Mutations {
  // mutation types
  SET_CLASSNAME_BY_POSITION = "appendBreadcrumb",
  PURGE_AUTH = "logOut",
  SET_AUTH = "setAuth",
  SET_USER = "setUser",
  SET_PASSWORD = "setPassword",
  SET_ERROR = "setError",
  SET_RETURN = "setReturn",
  SET_BREADCRUMB_MUTATION = "setBreadcrumbMutation",
  SET_LAYOUT_CONFIG = "setLayoutConfig",
  RESET_LAYOUT_CONFIG = "resetLayoutConfig",
  OVERRIDE_LAYOUT_CONFIG = "overrideLayoutConfig",
  OVERRIDE_PAGE_LAYOUT_CONFIG = "overridePageLayoutConfig",

  SET_ARTWORKS = "setArtworks",
  SET_ARTWORK = "SET_ARTWORK",

  SET_CONTACTS = "setContacts",
  SET_CONTACT = "setContact",

  SET_PUBLIC_USER = "setPublicUser",
}

export { Actions, Mutations };
