import { createStore } from "vuex";
import { config } from "vuex-module-decorators";

import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import ArtworkModule from "@/store/modules/ArtworkModule";
import ContactModule from "@/store/modules/ContactModule";
import PublicProfileModule from "@/store/modules/PublicProfileModule";

config.rawError = true;

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
    ArtworkModule,
    ContactModule,
    PublicProfileModule
  },
});

export default store;
