import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface User {
  id: number;
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  localite: string;
  telephone: string;
}

@Module
export default class PublicProfileModule extends VuexModule {
  errors = {};
  user = {} as User;

  /**
   * Get current user object
   * @returns User
   */
  get getPublicUser(): User {
    return this.user;
  }

  /**
   * Get errors
   * @returns array
   */
  get getPublicUserErrors() {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_PUBLIC_USER](user) {
    this.user = user;
  }

  @Action
  [Actions.LOAD_PUBLIC_USER](payload) {
    return ApiService.get("users/public-link/"+payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_PUBLIC_USER, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

}
