import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import axios from "axios";

export interface Artwork {
  id: number;
  titre: string;
  description: string;
  artwork_type: string;
  serie: string;
  technique: string;
  statut: string;
  localisation: string;
  hauteur: number;
  largeur: number;
  profondeur: number;
  poids: number;
  prix_artwork: number;
  is_signed: boolean;
  emplacement_signature: string;
  is_public: boolean;
  date_creation: string;
}

export interface ArtworkInfo {
  errors: unknown;
  artworks: Array<Artwork>;
}

@Module
export default class ArtworkModule extends VuexModule implements ArtworkInfo {
  errors = {};
  returnValues = {};
  artworks = [] as Array<Artwork>;
  artwork = {} as Artwork;

  /**
   * Get current artworks object
   * @returns Artworks
   */
  get listArtworks(): Array<Artwork> {
    return this.artworks;
  }

  get getArtwork(): Artwork {
    return this.artwork;
  }

  /**
   * Get errors
   * @returns array
   */
  get getArtworksErrors() {
    return this.errors;
  }

  get getArtworkReturns(){
    return this.returnValues;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_RETURN](returnValue) {
    this.returnValues = { ...returnValue };
  }

  @Mutation
  [Mutations.SET_ARTWORKS](data) {
    this.artworks.splice(0, this.artworks.length, ...data);
    this.errors = [];
  }

  @Mutation
  [Mutations.SET_ARTWORK](data) {
    this.artwork = data;
    this.errors = [];
  }


  @Action
  [Actions.LOAD_ARTWORKS]() {
    return ApiService.get("artworks")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ARTWORKS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.LOAD_ARTWORK](id) {
    this.context.commit(Mutations.SET_ARTWORK, {});
    return ApiService.get("artworks/"+id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ARTWORK, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.CREATE_ARTWORK](payload) {
    return ApiService.post("artworks/", payload)
      .then((response) => {
        this.context.commit(Mutations.SET_ERROR, {});
        this.context.commit(Mutations.SET_RETURN, response.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.EDIT_ARTWORK](payload) {
    return ApiService.put("artworks/"+payload.id+"/", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.DELETE_ARTWORK](payload) {
    return ApiService.delete("artworks/"+payload+"/")
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.CREATE_MEDIA](payload) {
    const formdata = new FormData();
    formdata.append('name', payload["name"]);
    formdata.append('artwork_id', payload["artwork_id"]);
    formdata.append('image', payload["image"]);

    axios.post('medias/', formdata, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
    }).then(() => {
      this.context.commit(Mutations.SET_ERROR, {});
    })
    .catch(({ response }) => {
      this.context.commit(Mutations.SET_ERROR, response.data);
    });
  }

  @Action
  [Actions.DELETE_MEDIA](payload) {
    return ApiService.delete("medias/"+payload+"/")
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

}
