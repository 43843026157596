import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface Contact {
  id: number;
  first_name: string;
  last_name: string;
  adresse: string;
  code_postal: string;
  localite: string;
  telephone: string;
  email: string;
  commentaire: string;
}

export interface ContactInfo {
  errors: unknown;
  contacts: Array<Contact>;
}

@Module
export default class ContactModule extends VuexModule implements ContactInfo {
  errors = {};
  returnValues = {};
  contacts = [] as Array<Contact>;
  contact = {} as Contact;

  /**
   * Get current artworks object
   * @returns Artworks
   */
  get listContacts(): Array<Contact> {
    return this.contacts;
  }

  get getContact(): Contact {
    return this.contact;
  }

  /**
   * Get errors
   * @returns array
   */
  get getContactsErrors() {
    return this.errors;
  }

  get getContactReturns(){
    return this.returnValues;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_RETURN](returnValue) {
    this.returnValues = { ...returnValue };
  }

  @Mutation
  [Mutations.SET_CONTACTS](data) {
    const datedContacts = data.map(contact => {
        contact["t_createdAt"] = new Date(contact["t_createdAt"]).toLocaleDateString("fr");
        return contact
    })
    this.contacts.splice(0, this.contacts.length, ...datedContacts);
    this.errors = [];
  }

  @Mutation
  [Mutations.SET_CONTACT](data) {
    this.contact = data;
    this.errors = [];
  }

  @Action
  [Actions.LOAD_CONTACTS]() {
    return ApiService.get("contacts")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CONTACTS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.LOAD_CONTACT](id) {
    this.context.commit(Mutations.SET_CONTACT, {});
    return ApiService.get("contacts/"+id)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CONTACT, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.CREATE_CONTACT](payload) {
    return ApiService.post("contacts/", payload)
      .then((response) => {
        this.context.commit(Mutations.SET_ERROR, {});
        this.context.commit(Mutations.SET_RETURN, response.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.EDIT_CONTACT](payload) {
    return ApiService.put("contacts/"+payload.id+"/", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.DELETE_CONTACT](payload) {
    return ApiService.delete("contacts/"+payload+"/")
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

}
