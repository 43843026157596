import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: 'dashboard',
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },
      {
        path: "contacts",
        name: "contacts",
        component: () => import("@/views/pygma/contacts/Contacts.vue"),
      },
      {
        path: "contacts/:id",
        name: "detail-contact",
        component: () => import("@/views/pygma/contacts/DetailContact.vue"),
      },
      {
        path: "contacts/:id/edit",
        name: "edit-contact",
        component: () => import("@/views/pygma/contacts/Edit.vue"),
      },
      {
        path: "contacts/add",
        name: "new-contact",
        component: () => import("@/views/pygma/contacts/Add.vue"),
      },
      {
        path: "artworks",
        name: "artworks",
        component: () => import("@/views/pygma/artworks/Artworks.vue"),
      },
      {
        path: "artworks/:id",
        name: "detail-artwork",
        component: () => import("@/views/pygma/artworks/DetailArtwork.vue"),
      },
      {
        path: "artworks/:id/edit",
        name: "edit-artwork",
        component: () => import("@/views/pygma/artworks/Edit.vue"),
      },
      {
        path: "artworks/add",
        name: "new-artworks",
        component: () => import("@/views/pygma/artworks/Add.vue"),
      },
      {
        path: "account",
        name: "account",
        component: () => import("@/views/pygma/account/Account.vue"),
        children: [
          {
            path: "overview",
            name: "account-overview",
            component: () => import("@/views/pygma/account/Overview.vue"),
          },
          {
            path: "settings",
            name: "account-settings",
            component: () => import("@/views/pygma/account/Settings.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/share/",
    component: () => import("@/layout/PublicLayout.vue"),
    meta: {
      allowAnonymous: true
    },
    children: [
      {
        path: ":id",
        name: "public-profile",
        component: () => import("@/views/pygma/public/PublicLink.vue"),
      },
    ]
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "sign-in",
        name: "sign-in",
        meta: {
          allowAnonymous: true
        },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-in/:activationCode",
        name: "activate",
        meta: {
          allowAnonymous: true
        },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        meta: {
          allowAnonymous: true
        },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        meta: {
          allowAnonymous: true
        },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
      {
        path: "/password-reset/:resetCode",
        name: "password-reset-code",
        meta: {
          allowAnonymous: true
        },
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = 'Pygma.io';
  if (!to.meta.allowAnonymous ) {
    store.commit(Mutations.RESET_LAYOUT_CONFIG);
    store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() });
    const authenticated = store.getters.isUserAuthenticated

    if (!authenticated) {
      router.push({ name: "sign-in" });
    } else{
      next()
    }
  } else {
    next()
  }  
});

export default router;
